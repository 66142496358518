import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: { pageTitle: "Zaloguj się", title: 'Zaloguj się' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    children: [
      {
        path: 'competitions',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Competitions.vue'),
        meta: { pageTitle: "Honesty Office - Konkursy", title: 'Konkursy' }
      },
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/UserSettings.vue'),
        meta: { pageTitle: "Honesty Office - Ustawienia użytkownika", title: 'Ustawienia użytkownika' }
      },
      {
        path: 'plan',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Plan.vue'),
        meta: { pageTitle: "Honesty Office - Plan i realizacja", title: 'Plan i realizacja' }
      },
      {
        path: 'structure',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/StructureView.vue'),
        meta: { pageTitle: "Honesty Office - Obraz organizacji", title: 'Obraz organizacji' }
      },
      {
        path: 'career',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Career.vue'),
        meta: { pageTitle: "Honesty Office - Kariera", title: 'Kariera' }
      },
      {
        path: 'leaderboard',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Leaderboard.vue'),
        meta: { pageTitle: "Honesty Office - Ranking", title: 'Ranking' }
      },
      {
        path: 'training',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Training.vue'),
        meta: { pageTitle: "Honesty Office - Szkolenia", title: 'Szkolenia' }
      },
      {
        path: 'library',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Library.vue'),
        meta: { pageTitle: "Honesty Office - Biblioteka", title: 'Biblioteka' }
      },
      {
        path: 'files',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Files.vue'),
        meta: { pageTitle: "Honesty Office - Pliki do pobrania", title: 'Pliki do pobrania' }
      },
      {
        path: 'clients',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Clients.vue'),
        meta: { pageTitle: "Honesty Office - Baza kontaktów", title: 'Baza kontaktów' }
      },
      {
        path: 'clientsWithDeals',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/ClientsWithDeals.vue'),
        meta: { pageTitle: "Honesty Office - Baza kontaktów", title: 'Baza kontaktów' }
      },
      {
        path: 'client',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../components/ClientView.vue'),
        meta: { pageTitle: "Honesty Office - Podgląd osoby", title: 'Podgląd osoby' }
      },
      {
        path: 'recruits',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Recruits.vue'),
        meta: { pageTitle: "Honesty Office - Baza kontaktów", title: 'Baza kontaktów' }
      },
      {
        path: 'recruit',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../components/RecruitView.vue'),
        meta: { pageTitle: "Honesty Office - Podgląd osoby", title: 'Podgląd osoby' }
      },
      {
        path: 'deals',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Deals.vue'),
        meta: { pageTitle: "Honesty Office - Umowy", title: 'Umowy' }
      },
      {
        path: 'statistics',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/Statistics.vue'),
        meta: { pageTitle: "Honesty Office - Analizy", title: 'Analizy' }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

